import React from "react";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  PinterestShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  FacebookIcon,
  PinterestIcon,
  TelegramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

function Footer1() {
  return (
    <>
      <footer id="footer">
        <div className="pad-l-45 pad-r-45">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6">
                <article className="footer-info-block">
                  <div className="section-sub-title">
                    <article className="section-title-body white">
                      <h4 className="head-title">VARDHAN WORLD</h4>
                    </article>
                  </div>
                  <p style={{ fontFamily: "JosefinSans, sans-serif" }}>
                    We at Vardhan World are here to aid your journey to success
                    in starting a start-up and establishing it as a flourishing
                    business. Our training encompasses all aspects of building
                    your start-up from scratch. Our industry experts will guide
                    you through this course and train you in different modules.
                    One of the most salient features of our course is the
                    hands-on practical knowledge that you will get. Industry
                    experts will guide and mentor you while working on ongoing
                    projects.
                  </p>
                </article>
              </div>
              <div className="col-xl-3 col-md-6">
                <article className="footer-info-block">
                  <div className="section-sub-title">
                    <article className="section-title-body white">
                      <h4 className="head-title">Services</h4>
                    </article>
                  </div>
                  <ul className="list-unstyled">
                    {/* <li><a href="/">Mosaic Design Web studio</a></li>
                                    <li><a href="/">Terms & Conditions of Uses</a></li>
                                    <li><a href="/">Privacy Policy</a></li>
                                    <li><a href="/">Sate Map</a></li> */}
                    <li>
                      <Link to="/startup-consulting">STARTUP CONSULTING</Link>
                    </li>
                    <li>
                      <Link to="/monetization-models">MONETIZATION MODELS</Link>
                    </li>
                    <li>
                      <Link to="/go-to-market-strategy">
                        GO TO MARKET STRATEGY
                      </Link>
                    </li>
                    <li>
                      <Link to="/investor-pitch-models">
                        INVESTOR PITCH MODELS
                      </Link>
                    </li>
                    <li>
                      <Link to="/sales-and-marketing-consulting">
                        SALES AND MARKETING CONSULTING
                      </Link>
                    </li>
                    <li>
                      <Link to="/branding">BRANDING</Link>
                    </li>
                    <li>
                      <Link to="/image-consulting">IMAGE CONSULTING</Link>
                    </li>
                    <li>
                      <Link to="/personal-counselling">
                        PERSONAL COUNSELLING
                      </Link>
                    </li>
                    <li>
                      <Link to="/career-counselling">CAREER COUNSELLING</Link>
                    </li>
                    <li>
                      <Link to="/digital-marketing">DIGITAL MARKETING</Link>
                    </li>
                    <li>
                      <Link to="/technology-services">TECHNOLOGY SERVICES</Link>
                    </li>
                    <li>
                      <Link to="/UI-UX">UI/UX</Link>
                    </li>
                    <li>
                      <Link to="/business-consulting">BUSINESS CONSULTING</Link>
                    </li>
                  </ul>
                </article>
              </div>
              <div className="col-xl-3 col-md-6">
                <article className="footer-info-block">
                  <div className="section-sub-title">
                    <article className="section-title-body white">
                      <h4 className="head-title">STARTUPs</h4>
                    </article>
                  </div>

                  {/* <p>
                    <a href="/">#Mosaic</a> coming up with their blog theme{" "}
                    <a href="/">#Design</a> show yourself from the best side
                  </p>
                  <p>
                    <a href="/">http://t.co/b0V7taIgvv</a>
                  </p> */}
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/startup">FLAPLIVE</Link>
                    </li>
                    <li>
                      <Link to="/startup">THALIVA</Link>
                    </li>
                    <li>
                      <Link to="/startup">OFFBG</Link>
                    </li>
                    <li>
                      <Link to="/startup">DOOR TAXI</Link>
                    </li>
                    <li>
                      <Link to="/startup">GYMLEY</Link>
                    </li>
                    <li>
                      <Link to="/startup">FHOKUS</Link>
                    </li>
                    <li>
                      <Link to="/startup">AUTOHONA</Link>
                    </li>
                    <li>
                      <Link to="/startup">WALIMA KI DAAWAT</Link>
                    </li>
                  </ul>
                </article>
              </div>
              {/* <div className="col-xl-3 col-md-6">
                <article className="footer-info-block">
                  <div className="section-sub-title">
                    <article className="section-title-body white">
                      <h4 className="head-title">VARDHAN STUDIOS</h4>
                    </article>
                  </div>
                  <ul className="list-unstyled">
                    
                    <li>
                      <Link to="/">Best Ever Beer Review Show</Link>
                    </li>
                    <li>
                      <Link to="/">MTV Dropout</Link>
                    </li>
                    <li>
                      <Link to="/">Story Board</Link>
                    </li>
                    <li>
                      <Link to="/">Story Script</Link>
                    </li>
                  </ul>
                </article>
              </div> */}
            </div>
            <div className="h25"></div>
            <div className="footer-bottom">
              <div className="row">
                <div className="col-md-8 order-1 col-xs-12 col-sm-8">
                  <ul className="footer-icon list-unstyled">
                    <li>
                      {" "}
                      <a
                        href="https://www.instagram.com/vardhanworld/"
                        target="_blank"
                      >
                        <ion-icon
                          name="logo-instagram"
                          style={{ fontSize: "26px", padding: "10px" }}
                        ></ion-icon>
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://www.facebook.com/vardhanworld0"
                        target="_blank"
                      >
                        <ion-icon
                          name="logo-facebook"
                          style={{ fontSize: "26px", padding: "10px" }}
                        ></ion-icon>
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://twitter.com/VardhanWorld"
                        target="_blank"
                      >
                        <ion-icon
                          name="logo-twitter"
                          style={{ fontSize: "26px", padding: "10px" }}
                        ></ion-icon>
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://www.youtube.com/@telugufounder"
                        target="_blank"
                      >
                        <ion-icon
                          name="logo-youtube"
                          style={{ fontSize: "26px", padding: "10px" }}
                        ></ion-icon>
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://www.linkedin.com/company/78852819/admin/"
                        target="_blank"
                      >
                        <ion-icon
                          name="logo-linkedin"
                          style={{ fontSize: "26px", padding: "10px" }}
                        ></ion-icon>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 col-xs-12 col-sm-4">
                  <div className="footer-logo">
                    {/* <img src="img/logo-big-shop1.png" alt="" /> */}
                    <img
                      src="images/logofinal.png"
                      height="100px"
                      width="100"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer1;
