import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

const DigitalMarketing = () => {
  return (
    <>
      <section id="about">
        <div className="bg-dark"></div>
        <div className="section-block-title">
          <div className="section-title">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-sm-10">
                  <article className="section-title-body">
                    <h1
                      className="head-title wow fadeInDown animated"
                      data-wow-duration="1.5s"
                    >
                      Digital Marketing
                    </h1>
                    <div
                      className="section-col wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    ></div>
                    <p
                      className="head-text wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    >
                      <Breadcrumb>
                        <Breadcrumb.Item>
                          <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Link to="/services">All Services</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                          Digital Marketing
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="inner-wrap">
        <div className="container">
          <div className="quote-icon">
            <img src="/images/quote.png" alt="" />
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="inner-top-title">
                <em>
                  Digital Strategies <br />
                  For <span>Evolving Businesses</span>
                </em>
              </div>
            </div>
          </div>
          <div className="title-section">
            <h2>Digital Marketing</h2>
          </div>
          <div className="inner-line-img">
            <img src="../img/bg/Mask-Group-2.jpg" alt="" />
          </div>
          <div className="inner-txt">
            <p>
              Does most of your audience spend a lot of time online? Most
              businesses today answer yes to this question. Reaching your target
              audience online and converting them into customers is easier with
              digital marketing. Digital marketing can be tailored to suit just 
              about any brand.
            </p>
            <p>
              Additionally, digital marketing has several advantages over
              traditional marketing. Digital marketing is flexible. As opposed
              to traditional marketing, where long-term campaigns are set in
              stone, digital marketing allows you to make changes during a
              campaign.
            </p>
            <p>
              You could believe internet marketing can't succeed unless you have
              a completely original idea. However, optimizing your efforts is
              the key to digital marketing success.
            </p>
            <p>
              When we talk about optimizing, we're talking about gradually
              trying modest modifications to get better results.
            </p>
            <p>
              Vardhan World keeps a close eye on each of its campaigns. It's
              nearly impossible to immediately locate the ideal keyword or
              targeting combination; therefore, that's precisely what we do!
              According to our analysis, these initiatives perform the best
              since they keep improving.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalMarketing;