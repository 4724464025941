import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Switch,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./ApplicationForm.css";
import axios from "axios";
import { Country, State, City } from "country-state-city";

const qualifications = [
  "Graduate",
  "Postgraduate",
  "Ph.D.",
  "Diploma",
  "Other",
];

// Step schemas for validation
const schemas = [
  yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone is required"),
    country: yup.string().required("Country is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    address: yup.string().required("Address is required"),
    linkedInProfile: yup.string(),
    qualification: yup.string().required("Qualification is required"),
    institution: yup.string().required("Institution is required"),
    course: yup.string(),
  }),
  yup.object().shape({
    hasBusiness: yup.boolean(),
    businessName: yup.string(),
    industry: yup.string(),
    duration: yup.number(),
    motivation: yup.string(),
    startupGoals: yup.string(),
    interest: yup.string(),
    declaration: yup.string().required("Please accept the declaration"),
  }),
];

const steps = ["Personal Details", "Entrepreneurial Experience"];

const ApplicationForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const schema = schemas[activeStep];

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedCountry = watch("country");
  const selectedState = watch("state");

  const countries = Country.getAllCountries();
  const states = selectedCountry
    ? State.getStatesOfCountry(selectedCountry)
    : [];
  console.log(states);
  const cities = selectedState
    ? City.getCitiesOfState(selectedCountry, selectedState)
    : [];
  console.log(cities);

  const onError = (val) => {
    console.log(val);
  };

  const onSubmit = async (data) => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    } else {
      try {
        const res = await axios.post(
          "http://20.85.227.59:4000/api/sendEmailToTeam",
          {
            ...data,
            country: countries?.find((q) => q?.isoCode === data?.country)?.name,
            state: states?.find((q) => q?.isoCode === data?.state)?.name,
            city: cities?.find((q) => q?.isoCode === data?.city)?.name,
          }
        );
        reset();
        setActiveStep(0);
        toast.success("Submitted successfully! Thank you");
      } catch (error) {
        console.log(error);
      }
      console.log("Final Data: ", data);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <div className="application-form-container">
      <div className="container pt-4">
        <Box mb={4}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {/* Step 1: Personal Details */}
          {activeStep === 0 && (
            <div>
              <h3 className="mb-5 fw-bolder">Personal Details</h3>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <label>Name</label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input {...field} placeholder="Your Full Name" />
                    )}
                  />
                  {errors.name?.message && (
                    <span className="text-danger">{errors.name?.message}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Email</label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <input {...field} placeholder="Email" />
                    )}
                  />
                  {errors.email?.message && (
                    <span className="text-danger">{errors.email?.message}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Phone</label>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <input type={"number"} {...field} placeholder="Phone" />
                    )}
                  />
                  {errors.phone?.message && (
                    <span className="text-danger">{errors.phone?.message}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Country</label>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <select {...field}>
                        <option value={null}>Select One</option>
                        {countries?.map((val) => (
                          <option value={val?.isoCode}>{val?.name}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.country?.message && (
                    <span className="text-danger">
                      {errors.country?.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>State</label>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <select {...field}>
                        <option value={null}>Select One</option>
                        {states?.map((val) => (
                          <option value={val?.isoCode}>{val?.name}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.state?.message && (
                    <span className="text-danger">{errors.state?.message}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>City</label>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <select {...field}>
                        <option value={null}>Select One</option>
                        {cities?.map((val) => (
                          <option value={val?.isoCode}>{val?.name}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.city?.message && (
                    <span className="text-danger">{errors.city?.message}</span>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <label>Address</label>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <input {...field} placeholder="Address" />
                    )}
                  />
                  {errors.address?.message && (
                    <span className="text-danger">
                      {errors.address?.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>LinkedIn Profile (optional)</label>
                  <Controller
                    name="linkedInProfile"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type={"url"}
                        placeholder="LinkedIn Profile"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <h3 className="mb-5 mt-5 fw-bolder">Educational Background</h3>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <label>Qualification</label>
                  <Controller
                    name="qualification"
                    control={control}
                    render={({ field }) => (
                      <select {...field}>
                        <option value={null}>Select One</option>
                        {qualifications?.map((val) => (
                          <option value={val}>{val}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.qualification?.message && (
                    <span className="text-danger">
                      {errors.qualification?.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Institution</label>
                  <Controller
                    name="institution"
                    control={control}
                    render={({ field }) => (
                      <input {...field} placeholder="Institution" />
                    )}
                  />
                  {errors.institution?.message && (
                    <span className="text-danger">
                      {errors.institution?.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Relevant Courses (if applicable)</label>
                  <Controller
                    name="course"
                    control={control}
                    render={({ field }) => (
                      <input {...field} placeholder="Relevant Courses " />
                    )}
                  />
                  {errors.course?.message && (
                    <span className="text-danger">
                      {errors.course?.message}
                    </span>
                  )}
                </Grid>
              </Grid>
            </div>
          )}

          {/* Step 2: Entrepreneurial Experience */}
          {activeStep === 1 && (
            <div>
              <h3 className="mb-4 fw-bolder">
                Entrepreneurial Experience (if applicable)
              </h3>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="d-flex justify-content-between">
                    <h5>Have you started or managed a business before?</h5>
                    <Controller
                      name="hasBusiness"
                      control={control}
                      render={({ field }) => (
                        <Switch {...field} checked={field.value} />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Business Name</label>
                  <Controller
                    name="businessName"
                    control={control}
                    render={({ field }) => (
                      <input {...field} placeholder="Business Name" />
                    )}
                  />
                  {errors.businessName?.message && (
                    <span className="text-danger">
                      {errors.businessName?.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Industry/ Sector</label>
                  <Controller
                    name="industry"
                    control={control}
                    render={({ field }) => (
                      <input {...field} placeholder="Industry/ Sector" />
                    )}
                  />
                  {errors.industry?.message && (
                    <span className="text-danger">
                      {errors.industry?.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Duration(in years)</label>
                  <Controller
                    name="duration"
                    control={control}
                    render={({ field }) => (
                      <input
                        type={"number"}
                        {...field}
                        placeholder="Duration"
                      />
                    )}
                  />
                  {errors.duration?.message && (
                    <span className="text-danger">
                      {errors.duration?.message}
                    </span>
                  )}
                </Grid>
              </Grid>
              <h3 className="my-3 fw-bolder">Course Interests</h3>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <label>What motivated you to apply for this course?</label>
                  <Controller
                    name="motivation"
                    control={control}
                    render={({ field }) => (
                      <textarea {...field} placeholder="Description" />
                    )}
                  />
                  {errors.motivation?.message && (
                    <span className="text-danger">
                      {errors.motivation?.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>What are your startup goals?</label>
                  <Controller
                    name="startupGoals"
                    control={control}
                    render={({ field }) => (
                      <textarea {...field} placeholder="Description" />
                    )}
                  />
                  {errors.startupGoals?.message && (
                    <span className="text-danger">
                      {errors.startupGoals?.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    Are you interested in any specific area of entrepreneurship?
                    (e.g., tech, social impact, sustainability)
                  </label>
                  <Controller
                    name="interest"
                    control={control}
                    render={({ field }) => (
                      <input {...field} placeholder="Details" />
                    )}
                  />
                  {errors.interest?.message && (
                    <span className="text-danger">
                      {errors.interest?.message}
                    </span>
                  )}
                </Grid>
              </Grid>
              <h3 className="my-3 fw-bolder">Declaration</h3>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="declaration"
                    control={control}
                    render={({ field }) => (
                      <div className="d-flex">
                        <input
                          style={{
                            height: "fit-content",
                            width: "fit-content",
                            marginRight: 10,
                            marginTop: 4,
                          }}
                          id="declaration"
                          type={"checkbox"}
                          {...field}
                        />
                        <label htmlFor="declaration">
                          I hereby declare that the information provided is
                          accurate and true. I understand that the course
                          organizers reserve the right to verify the
                          information.
                        </label>
                      </div>
                    )}
                  />
                  {errors.declaration?.message && (
                    <span className="text-danger">
                      {errors.declaration?.message}
                    </span>
                  )}
                </Grid>
              </Grid>
            </div>
          )}

          {/* Navigation Buttons */}
          <div className="d-flex justify-content-center mt-5 pb-5">
            <button className="btn cancel-button me-5" onClick={handleBack}>
              {activeStep === 0 ? "Cancel" : "Previous"}
            </button>
            <button type="submit" className="btn next-button">
              {activeStep === steps.length - 1 ? "Submit" : "Next"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplicationForm;
