export const blogs = [
    {
        _id:'001',
        title:'What is a Go To Market strategy? Significant Steps to Build a Go-To-Market Strategy?',
        text:'Launching a new product in the market can be daunting, especially with a vast number of competitors and even more discerning customers. A Go To Market strategy or a GTM strategy, as the name suggests, is the plan you follow to go into the market with a new product, service, or even a new brand. A GTM strategy a straightforward step-by-step plan of introducing introduce your product in the market. It provides a clear and concise path to be followed to bring your product to the attention of your customers and prompt them to buy.',
        text1 : "Many great products have failed due to lack of planning and strategizing before going into the market. Reasons are numerous, like bad product-market fit, improper pricing, poor timing of the launch, bad advertising strategy, and the list goes on and on. A GTM ensures that such fallacies are avoided and that the product or service gets the expected market response.",
        subhead : "Steps to Build a Go-To-Market Strategy",
        s_head1 : "1. Identify your buyer and end customer.",
        s_text1 : "The most important thing to consider when selling a product or a service is the end user and how they need the product or service. So, the first and foremost step is understanding the customer’s needs and helping you get a brief of how your product can meet the needs and add value. This, in turn influences the buyer to make a purchase(mind you, your end user and customer may or may not be the same).",
        s_head2 : "2. Zero in on a Pricing strategy",
        s_text2 : "Appraisal of your product is another crucial step in your GTM strategy. Pricing of the product needs to be done in such a way that the cost of production and profit margins are met, keeping in mind how well it meets the needs of the customer. One cannot expect the customer to pay a high price for a product that does not add much value.",
        s_head3 : "3. Time to spread the word",
        s_text3 : "Advertising your product through physical and digital mediums propels your lead generation. Everything can be used, from physical mediums like billboards and newspaper ads to digital mediums like ad platforms and social media marketing. However, the catch is identifying which medium to select for your product. For example, if your product is a software, a newspaper ad may not generate as many leads as an email marketing campaign or a social media marketing campaign. Thus, the target audience has to be kept in mind before you begin advertising your product.",
        s_head4 : "4. Getting your product to the end user",
        s_text4 : "Your distribution channels or pathways through which your product will reach your customer will dictate how easy it is for them to get solutions when they buy from you. Whether online or offline purchases, the customer experience should be as hassle-free as possible. This will directly affect your customer retention and your business’s future.",
        s_head5 : "5. Content Marketing and creating brand awareness",
        s_text5 : "Creating demand for the product by employing content creation through Keyword search, on-page and off-page SEO, etc., helps divert inbound traffic towards your product. Outbound traffic can be sought by cold outreach like emails, phone calls, etc. Once you have the traffic coming your way, conversion rates shoot up.",
        s_head6 : "6. Optimise optimize optimize",
        s_text6 : "You have to optimize every step of the way. You have to check what works out and what doesn’t work. Monitor your business and performance, and identify metrics to do the same (remember, numbers never lie). Check the length of your sales cycle and work on how you can reduce it. Iterate your lead conversion rates. Adjust your strategy to increase profitability for you and value addition for your customers.",
        s_head7 : "7. Work on retaining your loyal customer base",
        s_text7 : "You have to keep your customers coming back to you because they are the ones who will go back and market your product through word of mouth. They are the ones who will write reviews online and generate more leads for you.",
        conclusion : "A solid GTM strategy will ensure your product gets launched smoothly and earn you well-deserved profits and also delight your customers for future business opportunities.",
        
        url:'/blog/market-strategy',
        src:'img/preview/about-bg2.jpg',
        day:'01',
        month:'Jan',
        author:'Jhone',
        comments:'2'
    },
    {
        _id:'002',
        title:'Why startups need consulting',
        text:"A start-up is defined as Investopedia as a company that's in the initial stages of business. So this phase is typically full of uncertainties. It is also generally funded by the founders of the company. It’s a colossal project in itself getting the company’s business strategies, hiring the right employees, bagging new projects, researching the market, mining for funds, etc. One of these also becomes a reason why most startups fold in the very first year. So here is why startup consulting comes in handy. ",
        s_head1 : "1. Offer expert advice",
        s_text1 : "Since such consulting firms have been a part of the market for longer, they are well equipped to give expert advice, whether over-tried and tested business strategies or new and upcoming market trends.",
        s_head2 : "2. Offer help in creating a Road map",
        s_text2 : "A startup is generally a person’s brainchild or a group of people. So, even if they do have an idea of what their business should look like, they may lack the vision or knowledge to build up their dream bit by bit. A consultant, however, can give precise directions to such businesses. They help these businesses create an unambiguous road map toward their goal.",
        s_head3 : "3.  Help with efficiently utilizing resources",
        s_text3 : "With a limited number of resources, start-up owners are constantly faced with challenges regarding how they can use the available resources to the maximum. Hiring a consulting firm, therefore, becomes beneficial as they provide a clear and concise plan to make the most of the resources at hand.",
        s_head4 : "4. Customisation",
        s_text4 : "Startup consultancies are well aware of the fact that every client has specific needs to be fulfilled. A particular company may need help with operations, another may need assistance with market research, and another may need to integrate both and come up with solutions. They have to provide tailor-made solutions for every client, therefore.",
        s_head5 : "4. Market Research",
        s_text5 : "A startup can benefit a lot from consultant firms that keep abreast of all market trends. Robust market research by these consultancies serves the close analysis of the current market. This, in turn, helps the startup to internalize and use the information to strategize and plan for growth.",
        s_head6 : "5. Unbiased Feedback",
        s_text6 : "A startup consultancy provides an unbiased perspective to the client. A third-person view helps in pinpointing the chinks and ironing them out well in time.",
        s_head7 : "6. Cost Reduction",
        s_text7 : "Most of all, due to all the above reasons, a startup consultancy can optimize processes and decrease unnecessary expenditures of the startup. Leading to increased profitability of the business.",
        conclusion : "With a boom in the start-up culture, new companies enter the market arena every next day with ambitions of being a niche above their competitors and achieving resounding success. Therefore, they must be provided with all the tools essential to reach their fullest potential. Startup consultancies provide such means, making them an integral part of the market.",
        url:'/blog/startup-consulting',
        src:'img/preview/about-bg1.jpg',
        day:'13',
        month:'Jan',
        author:'Anna',
        comments:'12'
    },
    {
        _id:'003',
        title:'Vardhan World Entrepreneurship start-up training',
        text:'I’m sure you are here for a purpose. It is; you either want to be an entrepreneur and start a new business or are already at it and want someone to guide you with it. But again, the question arises, do you want to create a small business, or do you want to start a start-up? What is the difference, really? Well, a small business is something that one starts with the intent of earning profits but not necessarily expanding or growing or even causing much stir in the market. However, a start-up has a different purpose; it looks to grow, expand, disrupt the market, and beat competitors. It has a repeatable and scalable business model and is extremely dynamic in nature.',
        text1 : "We at Vardhan World are here to aid your journey to success in starting a start-up and establishing it as a flourishing business. Our training encompasses all aspects of building your start-up from scratch. Our industry experts will guide you through this course and train you in different modules. One of the most salient features of our course is the hands-on practical knowledge that you will get. Industry experts will guide and mentor you while working on ongoing projects. You will find our precisely designed curriculum is more complex and elaborate than most B schools. It has been designed to meet the needs of entrepreneurs looking to establish themselves as start-up founders in our ever-changing business landscape.",
        text2 : "Let’s talk about a few modules from our curriculum.",
        subhead : "Welcome Entrepreneurs!!",
        s_head1 : "1. Business models",
        s_text1 : "The business model is the foundation of any business. The choice of a business model depends on many factors, like the target customers, understanding the need for the product or service, pricing, costs, etc. Many times, two or more business models are merged to create a hybrid model that suits the business needs. You will learn how to pick and choose a suitable business model in this Entrepreneurship training.",
        s_head2 : "2. Revenue models",
        s_text2 : "A revenue model shows the pathway for the revenue to come in. It is how a company charges its customer for the products or services offered and how the customer pays. Different types of revenue models that you will come across during our training are transactional revenue models, subscription revenue models, web sales models, ad-based revenue models, and many more.",
        s_head3 : "3. GTM",
        s_text3 : "The go-to-market strategy is your roadmap when you go into the market with a product or service. A step-by-step product introduction plan goes a long way to retaining customers. Our training will empower you to build a go-to-market strategy that will ensure the smooth sailing of your start-up.",
        s_head4 : "4. Prototyping",
        s_text4 : "A prototype is an early sample, model, or release of a product built to test a concept or process. This is a crucial step as it allows one to view the idea in tangible form and avoid any mistakes or glitches in the future. It also allows the business to engage customers directly into the creation of the product, which results in intrigue on the customer’s part, which in turn lead to a buy-in. Our training will ensure that you are exposed to such prototyping environments and give you immense knowledge to develop prototypes independently.",
        s_head5 : "5. Create funding",
        s_text5 : "A huge prerequisite to starting a business is its Funding. One can fund their venture through self-funding, getting loans, finding an angel investor, Venture capital, etc.  Our training will prepare you to plan your approach to any of the above funding methods, whether using your own personal savings or attracting angel investors or venture capitalists.",
        s_head6 : "6. How To Pitch",
        s_text6 : "Presenting your business ideas to another party, which could be an investor or a customer, is called pitching. Dreary and dragged-out presentations are a thing of the past, and no one wants to see them anymore. Engaging and captivating your audience with your business pitch is an art. With this start-up training, you will learn the art of pitching, which is crucial to your start-up's funding, sales, and marketing processes.",
        s_head7 : "7. Burn rate prediction",
        s_text7 : "Burn rate prediction is essential; no wonder the judges of the famous TV show ‘Shark Tank’ stress on it so much. In simple terms, burn rate means how much you are spending before you start making money. In this training, you will learn how to compute burn rate and, most importantly, predict it so that you are better equipped for the future.",
        s_head8 : "8. Brand building and logo design",
        s_text8 : "Jeff Bezos says, “Your brand is what other people say about you when you’re not in the room.” Building your brand involves numerous steps, from your mission and vision to researching competitors to creating your logo. Creating your logo includes your unique color palette, iconography, fonts, image style, et al. We will train you to have an eye for such minute details.",
        s_head9 : "9. Minimum Viable Product ",
        s_text9 : "Minimum viable product is your product that is introduced in the market albeit with basic features only. It is just something to attract the attention of the customers. A final product is launched after internalizing the received feedback and making all the necessary modifications. During this startup training program, you will learn how to create a product with just enough features to be usable by early customers.",
        s_head10 : "10. Project Management",
        s_text10 : "Planning, execution, and completion of a project are easier said than done. It’s a monumental task of documentation, planning, tracking communication, and managing your resources through the project’s lifecycle. Imagine handling and executing multiple such projects, which entrepreneurs have to do as a part of their day. With us, you learn the art of managing multiple projects together effortlessly. You will learn skills, tools, and techniques to deliver value to customers while reaching your targets all at the same time.",
        s_head11 : "11. Tech mapping",
        s_text11 : "Gauging the tech needs of your start-up can make a world of difference when it comes to building and expansion of your business. Different businesses have different tech needs, from CRM systems, project management software, UI/UX, and data storage to cybersecurity. Understanding the needs of your business and mapping them out for smooth functioning is crucial. We train you to assess and chart out the same.",
        s_head12 : "12. UX mapping",
        s_text12 : "Understanding the target customer’s needs, conducting research, identifying pain points, and troubleshooting comprise UX mapping. Learning  UX mapping with us will help you narrow the gap between the customer’s needs and the product on offer.",
        s_head13 : "13. Key player mapping",
        s_text13 : "Mapping your company's key players is paramount for your business's success. These are the top performers on whom you prioritize your resources. You have to be able to map employee engagement and employee productivity to find these key players. With us, you will learn how to evaluate your employees on various parameters.",
        s_head14 : "14. Communication skills",
        s_text14 : "Being able to communicate and express oneself is a cardinal skill in the dynamic business world. In this training, you will learn all the written and spoken communications skills that will be extra beneficial for you to run your business.",
        s_head15 : "15. CUG",
        s_text15 : "Closed user group testing involves testing the product or service within a small group of target customers who get limited access to the product or service. This kind of testing provides your business with the necessary feedback from the target audience and helps improve product quality. Our training will provide you with enough knowledge in this area.",
        conclusion : "These topics and more are covered in the curriculum. Lastly, one can opt to learn in a business school. But a business school cannot offer you opportunities to work on ongoing projects and have your trainers or teachers mentor you through the process. The best business schools overlook many of the above modules, leaving you ill-prepared to take your giant leap. So I implore you to make the right choice and pick Vardhan world, and we shall be there to hand hold you and shepherd you to your goals.",
        url:'/blog/startup-training',
        src:'img/preview/about-bg3.jpg',
        day:'29',
        month:'Jan',
        author:'Admin',
        comments:'15'
    },
    // {
    //     _id:'004',
    //     title:'Anema  features and specification',
    //     text:'Cum  amet consectetur, adipisicing elit. Cum vel officiis perferendis libero assumenda mollitia maxime veniam at, maiores non neque, iusto error earum minima quidem adipisci. Ullam eius provident amet nulla quam dicta dolorum harum perspiciatis odio repudiandae fugit recusandae dolor, expedita voluptatibus in ab consequatur ratione facere porro',
    //     url:'/',
    //     src:'img/preview/blog3.jpg',
    //     day:'03',
    //     month:'Feb',
    //     author:'Admin',
    //     comments:'33'
    // },
    // {
    //     _id:'005',
    //     title:'React Multipurpose One page template',
    //     text:'Lorem to error earum minima quidem adipisci. Ullam eius provident amet nulla quam dicta dolorum harum perspiciatis odio repudiandae fugit recusandae dolor, expedita voluptatibus in ab consequatur ratione facere porro',
    //     url:'/',
    //     src:'img/preview/blog4.jpg',
    //     day:'15',
    //     month:'Feb',
    //     author:'Jhone',
    //     comments:'22'
    // },
    // {
    //     _id:'006',
    //     title:'Smartphones Ui and design ratings',
    //     text:' Cum expedita voluptatibus in ab consequatur ratione facere porro Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum vel officiis perferendis libero assumenda mollitia maxime veniam at, maiores non neque, iusto error earum minima quidem adipisci. expedita voluptatibus in ab consequatur ratione facere porro Lorem ipsum dolor sit amet consectetur, adipisicing elit.  Ullam eius provident amet nulla quam dicta andae dolor, expedita voluptatibus in ab consequatur ratione facere porro',
    //     url:'/',
    //     src:'img/preview/blog5.jpg',
    //     day:'04',
    //     month:'Mar',
    //     author:'Tom',
    //     comments:'7'
    // }
]