import React from 'react';
import { blogs } from './blog-data';
import './Blogs2'
// import {useHistory} from "react-router-dom"



const BlogDetail = (props) => {
    return(
        <>
{blogs.map((item)=>(
    item._id==props.id?(
        <>
       

        <section id="business-consulting">
        <div className="bg-dark"></div>
        {/* <div className="section-block-title light">
          <div className="section-title">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-sm-10">
                  <article className="section-title-body">
                    <h1
                      className="head-title wow fadeInDown animated"
                      data-wow-duration="1.5s"
                    >
                      {item.title}
                    </h1>
                    <div
                      className="section-col wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    ></div>
                    <p
                      className="head-text wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    >
                      Enhance your Brand identity into your customers’ minds
                      using our Strategies
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="color-scheme-blur">
          <div className="container">
            <div className="row gx-5 align-items-center">
            
              <div className="col-md-12 col-sm-12">
                <div className="block pad-l-30">
                  <div
                    className="widget-block wow fadeInDown animated"
                    data-wow-duration="1.5s"
                  >
                    <h4 className="widget-title">
                      {item.title}
                    </h4>
                    <p>{item.text}</p>
                    <p>{item.text1}</p>
                    <p>{item.text2}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head1}</h5>
                    <p>{item.s_text1}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head2}</h5>
                    <p>{item.s_text2}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head3}</h5>
                    <p>{item.s_text3}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head4}</h5>
                    <p>{item.s_text4}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head5}</h5>
                    <p>{item.s_text5}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head6}</h5>
                    <p>{item.s_text6}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head7}</h5>
                    <p>{item.s_text7}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head8}</h5>
                    <p>{item.s_text8}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head9}</h5>
                    <p>{item.s_text9}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head10}</h5>
                    <p>{item.s_text10}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head11}</h5>
                    <p>{item.s_text11}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head12}</h5>
                    <p>{item.s_text12}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head13}</h5>
                    <p>{item.s_text13}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head14}</h5>
                    <p>{item.s_text14}</p>
                    <h5 style={{color : "#fff"}}>{item.s_head15}</h5>
                    <p>{item.s_text15}</p>
                    <p>{item.conclusion}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        </>

    ):null

))}

</>
    
  )
}

export default BlogDetail;