import React from "react";
import ContactForm from "./../Contact/ContactForm";
import {
  FacebookShareButton,
  PinterestShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  InstapaperShareButton,
} from "react-share";
import {
  FacebookIcon,
  PinterestIcon,
  TelegramIcon,
  LinkedinIcon,
  TwitterIcon,
  InstapaperIcon,
  Instagram,
} from "react-share";

function Contact1() {
  return (
    <>
      <section id="contact" className="light">
        <div className="bg-light"></div>
        <div className="bg-50-l"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12  order-md-1 justify-content-center d-flex align-items-center">
              <div className="pad-l-45 pad-r-45">
                <div className="section-block-title">
                  <div className="section-title">
                    <article className="section-title-body mobile-mb-0">
                      <h1
                        className="head-title wow fadeInDown animated"
                        data-wow-duration="1.5s"
                      >
                        Contact
                      </h1>
                      <div
                        className="section-col wow fadeInUp animated"
                        data-wow-duration="1.5s"
                      ></div>
                      <p
                        className="head-text wow fadeInUp animated"
                        data-wow-duration="1.5s"
                      >
                        We wait you with happy time. Contact us with socials
                      </p>
                      <div className="h25"></div>
                      <div
                        className="contact-icon wow fadeInUp animated"
                        data-wow-duration="1.5s"
                      >
                        <ul className="list-unstyled">
                          <li>
                            {" "}
                            <a
                              href="https://www.instagram.com/vardhanworld/"
                              target="_blank"
                              className="iconsocial"
                            >
                              <ion-icon name="logo-instagram"></ion-icon>
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a
                              href="https://www.facebook.com/vardhanworld0"
                              target="_blank"
                              className="iconsocial"
                            >
                              <ion-icon name="logo-facebook"></ion-icon>
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a
                              href="https://twitter.com/VardhanWorld"
                              target="_blank"
                              className="iconsocial"
                            >
                              <ion-icon name="logo-twitter"></ion-icon>
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a
                              href="https://www.youtube.com/@telugufounder"
                              target="_blank"
                              className="iconsocial"
                            >
                              <ion-icon name="logo-youtube"></ion-icon>
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a
                              href="https://www.linkedin.com/company/78852819/admin/"
                              target="_blank"
                              className="iconsocial"
                            >
                              <ion-icon name="logo-linkedin"></ion-icon>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="pad-l-45 pad-r-45 block">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="widget-block mobile-center">
                      <h4 className="widget-title">
                        <i className="ion-android-pin"></i>Address:
                      </h4>
                      <p>
                        3rd floor, 5-109/3/B/1, road, opp. JRC,
                        <br /> Jubilee Hills, Rai Durg,<br/>Hyderabad,500033
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="widget-block mobile-center">
                      <h4 className="widget-title">
                        <i className="ion-android-call"></i>Phone:
                      </h4>
                      <p>
                        +91 8309145496
                      </p>
                    </div>
                  </div>
                </div>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact1;
