import React from "react";
import ContactForm from "./../Contact/ContactForm";
import {
  FacebookShareButton,
  PinterestShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  FacebookIcon,
  PinterestIcon,
  TelegramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { Link } from "react-router-dom";
function Contact4() {
  return (
    <>
      <section id="contact" className="light">
        <div className="bg-light"></div>
        <div className="block">
          <div className="container">
            <div className="row ">
              <div className="col-md-6">
                <div className="section-sub-title">
                  <article className="section-title-body">
                    <h1 className="head-title">Meet with you </h1>
                  </article>
                </div>
                <div className="widget-block dark">
                  <h4 className="widget-title">
                    <i className="ion-pound"></i>Vardhan Studio
                  </h4>
                  <h4 className="widget-title">
                    <i className="ion-map"></i>3rd floor, 5-109/3/B/1, road,
                    opp. J R C, Jubilee Hills, Rai Durg
                  </h4>
                  <br />

                  <a
                    href="https://www.instagram.com/vardhanworld/"
                    target="_blank"
                    className="iconsocial"
                  >
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                  <a
                    href="https://www.facebook.com/vardhanworld0"
                    target="_blank"
                    className="iconsocial"
                  >
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                  <a
                    href="https://twitter.com/VardhanWorld"
                    target="_blank"
                    className="iconsocial"
                  >
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                  <a
                    href="https://www.youtube.com/@telugufounder"
                    target="_blank"
                    className="iconsocial"
                  >
                    <ion-icon name="logo-youtube"></ion-icon>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/78852819/admin/"
                    target="_blank"
                    className="iconsocial"
                  >
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </div>
                <article className="section-title-body">
                    <h4 className="head-title">Consulting </h4>
                  </article>
                <div className="row">
                  {/* <div className="col-lg-6">
                    <div className="widget-block dark">
                      <h4 className="widget-title">
                        <i className="ion-android-call"></i>Phone:
                      </h4>
                      <p>
                      +91 9555755540
                       
                      </p>
                    </div> */}
                  {/* </div> */}
                  <div className="col-lg-6">
                    <div className="widget-block dark">
                      <h4 className="widget-title">
                        <i className="ion-email"></i>Email:
                      </h4>
                      <p>
                      vardhan@vardhanworld.com
                        <br />
                        support@vardhanworld.com
                      </p>
                    </div>
                  </div>
                </div>
                <article className="section-title-body">
                    <h4 className="head-title">Start Up Entrepreneurship Program </h4>
                  </article>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="widget-block dark">
                      <h4 className="widget-title">
                        <i className="ion-android-call"></i>Phone:
                      </h4>
                      <p>
                      +91 8309145496
                        
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="widget-block dark">
                      <h4 className="widget-title">
                        <i className="ion-email"></i>Email:
                      </h4>
                      <p>
                       
                        thevardhanworld@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 align-items-center">
                <div className="form-light">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact4;
