import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

const InvestorPitchModels = () => {
  return (
    <>
      <section id="about">
        <div className="bg-dark"></div>
        <div className="section-block-title">
          <div className="section-title">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-sm-10">
                  <article className="section-title-body">
                    <h1
                      className="head-title wow fadeInDown animated"
                      data-wow-duration="1.5s"
                    >
                      Investor Pitch Models
                    </h1>
                    <div
                      className="section-col wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    ></div>
                    <p
                      className="head-text wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    >
                      <Breadcrumb>
                        <Breadcrumb.Item>
                          <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Link to="/services">All Services</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                          Investor Pitch Models
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="inner-wrap">
        <div className="container">
          <div className="quote-icon">
            <img src="/images/quote.png" alt="" />
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="inner-top-title">
                <em>
                  Get Funded with <span>Smart Pitch Decks</span>
                </em>
              </div>
            </div>
          </div>
          <div className="title-section">
            <h2>Investor Pitch Models</h2>
          </div>
          <div className="inner-line-img">
            <img src="../img/bg/Mask-Group-6.jpg" alt="" />
          </div>
          <div className="inner-txt">
            <p>
              A comprehensive business strategy is the first step in developing
              a winning pitch. It is, therefore, up to you to decide what makes
              your company attractive and worthwhile for investment. Even if you
              have a detailed analysis of how you compare to the competitors
              across several industries on five pages of your proven financial
              history, you still won't be able to cover everything. Because you
              often only have ten minutes to make your case when presenting to
              angel investors and venture capitalists for the first time.
            </p>
            <p>
              A slide presentation called an investor pitch model aims to
              describe your business, business plan, and vision. Reaching out to
              new investors or making a pitch to prospective investors are just
              two of the many uses for an investor pitch model presentation.
              Depending on the entrepreneur, the angle can be delivered in
              various ways.
            </p>
            <p>
              An investor can learn more about your business idea and connect
              with your startup ideas through an impressive pitch model, which
              can lead to funding for your business. Investor In-person or
              online meetings with potential clients involve presenting a pitch
              model.
            </p>
            <p>
              Your present market share, your revenue strategy, and a financial
              breakdown outlining how you will use it should all be included in
              an investor pitch model.
            </p>
            <p>
              In order for the investor to comprehend the business potential, it
              uses the slides to simply illustrate the company and its
              likelihood of success. An investor pitch deck presentation is made
              up of a number of slides that assist the entrepreneur in
              explaining their company in greater detail.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorPitchModels;