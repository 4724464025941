import React from 'react'
import { blogs } from './blog-data'
import BlogDetail from './BlogDetail'
import BlogItem from './BlogItem'



function Blogs2() {
    return (
        <section  id="blog" className="single-bg">
            <div className="section-block-title">
                <div className="bg-dark"></div>

                    <div className="container">
                        <div className="section-sub-title center">
                            <article className="section-title-body white">
                                <h1 className="head-title">Our Recent <span>Blogs</span></h1>
                            </article>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {
                                blogs.map((blog)=>(
                                    <div className="col-md-6 col-xl-4" key={blog._id}>
                                        <BlogItem 
                                        title={blog.title} 
                                        text={blog.text}
                                        text1={blog.text1}
                                        url={blog.url}
                                        src={blog.src}
                                        day={blog.day}
                                        month={blog.month}
                                        author={blog.author}
                                        comments={blog.comments}
                                         />
                                    </div>
                                ))}
                            
                        </div>
                    </div>
                <div className="h50"></div>
            </div>       
        </section>
    )
}

export default Blogs2
